// math.js

// Function to fetch data and parse extraBall values
async function fetchDataAndParse(url) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        
        // Extract extraBall values from the fetched data
        const extraBalls = data.data.map(row => row[10]); // Assuming extraBall is at index 10 in each row

        return extraBalls;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
}

// Function to generate random numbers based on weighted frequency within a specified range
function generateRandomNumberFromExtraBalls(extraBalls, maxNumber = extra_ball_maxnum) {
    // Count frequency of each extraBall
    const frequencyMap = extraBalls.reduce((map, ball) => {
        map[ball] = (map[ball] || 0) + 1;
        return map;
    }, {});

    // Normalize frequencies to probabilities
    const totalWeight = extraBalls.length;
    const probabilities = extraBalls.map(ball => frequencyMap[ball] / totalWeight);

    // Generate a random number based on weighted probabilities
    let rand = Math.random();
    for (let i = 0; i < extraBalls.length; i++) {
        if (rand < probabilities[i]) {
            // Return a random number from 1 to maxNumber
            return Math.floor(Math.random() * maxNumber) + 1;
        }
        rand -= probabilities[i];
    }

    // In case probabilities didn't sum to 1 perfectly, return a number within the range
    return Math.floor(Math.random() * maxNumber) + 1;
}


// Function to randomly pick one form based on weighted probabilities
function randomlyPickForm() {
    let rand = Math.random();
    for (let i = 0; i < patterns_dump.length; i++) {
        if (rand < probabilities[i]) {
            return patterns_dump[i].form;
        }
        rand -= probabilities[i];
    }
    // In case probabilities didn't sum to 1 perfectly
    return patterns_dump[patterns_dump.length - 1].form;
}

// Expose the functions to the global scope
window.fetchDataAndParse = fetchDataAndParse;
window.generateRandomNumberFromExtraBalls = generateRandomNumberFromExtraBalls;
window.randomlyPickForm = randomlyPickForm;
window.inverseRoundlow10 = inverseRoundlow10;

// Your existing functions from before (assuming they are already defined)
function roundlow10(ball) {
    return (Math.floor(ball / 10) * 10 + 10) / 10;
}

function inverseRoundlow10(target) {
    const min = Math.round(target * 10 - 10);
    const max = Math.round(target * 10);
    const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    return randomNumber <= 0 ? 1 : randomNumber;
}

function generateNumbersFromPickedForm(numRows = 1) {
    const results = [];

    for (let row = 0; row < numRows; row++) {
        const chosenForm = randomlyPickForm();
        const formRow = patterns_dump.find(pattern => pattern.form === chosenForm);

        if (formRow) {
            const { '1st': first, '2nd': second, '3rd': third, '4th': fourth, '5th': fifth } = formRow;
            const generatedNumbers = new Set(); // Use a Set to store unique numbers

            while (generatedNumbers.size < 5) {
                generatedNumbers.add(inverseRoundlow10(first));
                generatedNumbers.add(inverseRoundlow10(second));
                generatedNumbers.add(inverseRoundlow10(third));
                generatedNumbers.add(inverseRoundlow10(fourth));
                generatedNumbers.add(inverseRoundlow10(fifth));
            }

            results.push({ chosenForm, generatedNumbers: [...generatedNumbers] });
        } else {
            console.error(`Form ${chosenForm} not found in patterns data.`);
        }
    }

    return results;
}
